import React, { useState } from "react";
import Lottie from "lottie-react";
import SignupAnimationData from "../assets/signup.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Signup = () => {
  // code for form state
  const [schoolName, setSchoolName] = useState('');
  const [personName, setPersonName] = useState('');
  const [personPhone, setPersonPhone] = useState('');
  // code for success notify
  const successNotify = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  // code for error notify
  const errorNotify = (msg) =>
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  // code for handle submit form
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    let getAPI = await fetch(process.env.REACT_APP_CONTACT, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ schoolName, personName, personPhone })
    })
    // checking the message result
    getAPI = await getAPI.json();
    if (getAPI.message === true) {
      setSchoolName('')
      setPersonName('')
      setPersonPhone('')
      successNotify("Thanks For Connecting, We Will Get ASAP")
    } else {
      setSchoolName('')
      setPersonName('')
      setPersonPhone('')
      errorNotify("Message Faild, Please Try Again")
    }
  }
  return (
    <div id="signup" className="w-full py-16  text-white px-4">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3 ">
        <div className="lg:col-span-2 my-4">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2 ">
            Want To Give Your Institution A Better ERP?
          </h1>
          <p>
            Share your contact information, and we'll reach out to you promptly!
          </p>
          <Lottie
            animationData={SignupAnimationData}
            loop={true}
            className="md:w-[500px] sm:w-[400px] sm:h-[400px] h-[500px] lg:my-[-70px] lg:mx-40"
          />
        </div>
        <div className="md:my-4  ">
          <form onSubmit={handleSubmitForm}>
            <div className="flex flex-col  items-center justify-between w-full ">
              <input
                className="p-3 mb-3 mt-2 w-full rounded-md text-black"
                type="text"
                placeholder="School Name" required minLength={3} maxLength={45} value={schoolName} onChange={(txt) => { setSchoolName(txt.target.value) }}
              />
              <input
                className="p-3 mb-3 w-full rounded-md text-black"
                type="text"
                placeholder="Your Name" required minLength={2} maxLength={35} value={personName} onChange={(txt) => { setPersonName(txt.target.value) }}
              />
              <input
                className="p-3 mb-3 w-full rounded-md text-black"
                type="text"
                placeholder="Phone Number" required pattern="[789][0-9]{9}" title="Invalid Phone No & Not Include +91" value={personPhone} onChange={(txt) => { setPersonPhone(txt.target.value) }}
              />
              <button type="submit" className="bg-[#00df9a] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-3">
                Connect
              </button>
            </div>
          </form>
          <p>
            Feel Free To Connect Us, We Are Happy To Help You. {" "}
            <span className="text-[#00df9a]  text-center">edusmartly</span>
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>

  );
};

export default Signup;
