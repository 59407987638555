import React from "react";
import Single from "../assets/single.png";
import Double from "../assets/double.png";
import Triple from "../assets/triple.png";
import { Link } from "react-scroll";

const Cards = () => {
  return (
    <div id="pricing" className="w-full py-[10rem] px-4 bg-white">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 ">
          <img
            className="w-20 mx-auto mt-[-3rem] bg-white"
            src={Single}
            alt=""
          />
          <h2 className="text-2xl font-bold text-center py-8">Basic Plan</h2>
          <p className="text-center text-4xl font-bold">₹7 / student</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Website Access</p>
            <p className="py-2 border-b mx-8">Parent Control</p>
            <p className="py-2 border-b mx-8">Fee Management</p>
            <p className="py-2 border-b mx-8">Student Management</p>
            <p className="py-2 border-b mx-8">Staff Management</p>
          </div>
          <Link to="signup"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500} className="my-6 mx-auto"> <button className="bg-[#00df9a] w-[200px] rounded-md font-medium px-6 py-3">
              Start Trial
            </button></Link>
        </div>
        <div className="w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
          <img
            className="w-20 mx-auto mt-[-3rem] bg-transparent"
            src={Double}
            alt="/"
          />
          <h2 className="text-2xl font-bold text-center py-8">Premium Plan</h2>
          <p className="text-center text-4xl font-bold">₹15 / student</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">All Basic Plan Features</p>
            <p className="py-2 border-b mx-8">Mobile App Access</p>
            <p className="py-2 border-b mx-8">Library & Transport Management</p>
            <p className="py-2 border-b mx-8">Online Payment</p>
          </div>
          <Link to="signup"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500} className="my-6 mx-auto"> <button className="bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">
            Start Trial
          </button></Link>
        </div>
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img
            className="w-20 mx-auto mt-[-4rem] bg-white"
            src={Triple}
            alt="/"
          />
          <h2 className="text-2xl font-bold text-center py-8">Custom Plan</h2>
          <p className="text-center text-4xl font-bold">Custom Price</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">All Premium Plan Features</p>
            <p className="py-2 border-b mx-8">Student Progress Report</p>
            <p className="py-2 border-b mx-8">Teacher Progress Report</p>
            <p className="py-2 border-b mx-8">AI Integrations</p>
          </div>
          <Link to="signup"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500} className="my-6 mx-auto">  <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">
            Start Trial
          </button></Link>
        </div>
      </div>
    </div>
  );
};

export default Cards;
